import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  buttonURL?: string
  benefitsTitle?: string
  benefitsList?: string[]
  languageCode: string
  subtitle?: string
  title?: string
}

export const Info = memo(function Info({
  buttonURL,
  benefitsTitle,
  benefitsList,
  languageCode,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <SubTitle>{subtitle}</SubTitle> : null}
        {buttonURL ? (
          <CTA
            label={useVocabularyData('explore-milan', languageCode)}
            rel="noopener"
            target="_blank"
            URL={buttonURL}
            variant="corners"
          />
        ) : null}
      </Wrapper>
      <Benefits>
        {benefitsTitle ? (
          <FadeInUp>
            <BenefitsTitle>{benefitsTitle}</BenefitsTitle>
          </FadeInUp>
        ) : null}
        {benefitsList
          ? benefitsList.map((item, index) => (
              <Benefit key={index}>
                <FadeInUp>
                  {
                    // @ts-ignore
                    item.label
                  }
                </FadeInUp>
              </Benefit>
            ))
          : null}
      </Benefits>
    </Container>
  )
})

const Container = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Wrapper = styled.div`
  background: #edf4f1;
  padding: 3.5rem 3.75rem;
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    margin-top: 3.75rem;
    padding: 3rem 1.9375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.375rem;
`

const SubTitle = styled.div`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const CTA = styled(Button)`
  display: block;
  max-width: max-content;
  margin: 3.125rem auto 0;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

const Benefits = styled.div`
  margin-top: 5rem;
  padding: 0 2.5vw 3.75rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    border: 3px solid #edf4f1;
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 0;
    padding-bottom: 3rem;
  }
`

const BenefitsTitle = styled.div`
  max-width: 240px;
  padding: 0 10px;
  margin: 0 auto 3rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.375rem;
  position: relative;
  text-align: center;
  z-index: 2;
`

const Benefit = styled.div`
  max-width: 200px;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  margin: 1.275rem auto 0;

  > div {
    padding-left: 1.25rem;
    position: relative;
    &:before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      background: #53a78b;
      position: absolute;
      top: 0.25rem;
      left: 0;
      transform: rotate(45deg);
    }
  }
`
