import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Info } from './Info'

export interface Props {
  benefitsList?: string[]
  benefitsTitle?: string
  buttonURL?: string
  description?: string
  languageCode: string
  infoSubtitle?: string
  infoTitle?: string
  title?: string
}

export const EventContent = memo(function EventContent({
  benefitsList,
  benefitsTitle,
  buttonURL,
  description,
  languageCode,
  infoSubtitle,
  infoTitle,
  title,
}: Props) {
  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>
        <Info
          buttonURL={buttonURL}
          benefitsList={benefitsList}
          benefitsTitle={benefitsTitle}
          languageCode={languageCode}
          subtitle={infoSubtitle}
          title={infoTitle}
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 0 auto 11.875rem;
  padding: 0 15.5vw;

  @media (max-width: 1920px) {
    padding: 0 11.667vw;
  }

  @media (max-width: 1399px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin-bottom: 3.75rem;
  }
`

const LeftSide = styled.div`
  width: 64%;
  margin-top: 5.3125rem;
  padding-right: 8.194vw;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3.75rem;
    padding-right: 0;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;

  @media (max-width: 1023px) {
    font-size: 30px;
  }
`

const Description = styled.div`
  margin-top: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  strong {
    font-weight: 600;
  }

  p {
    margin: inherit;
  }
`

const RightSide = styled.div`
  width: 32%;
  margin-top: -4.375rem;

  @media (max-width: 1199px) {
    width: 36%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 6rem;
  }
`
